<template>
  <user-profile
    :platform="$route.params.platform"
    :username="$route.params.username"
    :account-id="$route.query.accountId || $route.params.username"
    :report-user-id="$route.query.reportUserId"
    :report-id="$route.query.reportId"
  />
</template>

<script>
const UserProfile = () => import(/* webpackChunkName: "user-profile-insights" */ "@/components/common/UserProfile.vue")

export default {
  name: "InfluencerInsightReport",

  components: {
    UserProfile
  }
}
</script>
